import { useState, useEffect } from 'react'

import i18n, { langs } from './i18n.js'
import ascii from './ascii.txt'
import Video from './Video.js'

import instructions from './Instructions.js'
import hello from './Hello.js'
import rsvp from './Rsvp.js'
import schedule from './Schedule.js'

import './App.css'

const SECTIONS = { hello, rsvp, schedule, instructions }
const SECTION_KEYS = Object.keys(SECTIONS)

function App() {
  const [lang, setLang] = useState('gb')
  const [section, setSection] = useState('hello')
  const [inst, setInst] = useState(lang === 'fr' ? 'localCar' : 'abroad')

  const t = i18n[lang]

  useEffect(() => {
    const navLangs = navigator.languages.reduce((acc, k) => ((acc[k] = 1), acc), {})
    const isPorenko = navLangs.uk || navLangs['uk-UA'] || navLangs['ru-UA']
    const isFrog = navLangs.fr || navLangs['fr-FR']

    setLang(isPorenko ? 'ua' : isFrog ? 'fr' : 'gb')

    fetch(ascii)
      .then(r => r.text())
      .then(console.log)
  }, [])

  return (
    <div className="f fdc fac">
      <div id="hero-container">
        <Video src="https://mediafiles.balthazar.dev/dl/wedding-bg.mp4" id="main-vid" />

        <div className="f fdc title">
          <h1>NATALIYA x BALTHAZAR</h1>
          <h3>{t.newChapter}</h3>
          <h3>39.5883° N, 105.6438° W</h3>
        </div>
      </div>

      <div id="menu">
        <div id="links">
          {SECTION_KEYS.map(name => (
            <div onClick={() => setSection(name)} className="f fjc" key={name}>
              {t[name]}
            </div>
          ))}
        </div>

        <div id="flags">
          {langs.map(lang => (
            <img onClick={() => setLang(lang)} src={`/${lang}.png`} width={24} key={lang} />
          ))}
        </div>
      </div>

      <div id="content" className="cmt64">
        {SECTIONS[section]({ lang, inst, setInst })}
      </div>
    </div>
  )
}

export default App
