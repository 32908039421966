import capitalize from 'lodash/capitalize'

import i18n from './i18n.js'

const CATS = ['localCar', 'localTrain', 'abroad']

export default function Instructions({ lang, inst, setInst }) {
  const t = i18n[lang]

  return (
    <>
      <p className="t">{t.instructionsAccommodations}</p>

      <div className="toggle">
        {CATS.map(opt => (
          <span onClick={() => setInst(opt)} className={opt === inst ? 'active' : ''} key={opt}>
            {t[`inst${capitalize(opt)}Title`]}
          </span>
        ))}
      </div>

      {inst === 'abroad' && (
        <>
          <p className="t">{t.instructionsParis1}</p>
          <p className="t">{t.instructionsParis2}</p>
          <p className="t">{t.instructionsParis3}</p>
          <p className="t">{t.instructionsTrain}</p>
        </>
      )}

      {inst === 'localCar' && (
        <>
          <p className="t">{t.instructionsCar}</p>

          <div className="f acc cml16">
            <a href="https://maps.app.goo.gl/9o5orLAHc3zmi75KA" target="_blank" rel="noreferrer">
              GPS
            </a>

            <span>47°18'39.2"N 2°51'47.5"E</span>

            <a href="https://maps.app.goo.gl/avXAz9zt72h1rfo67" target="_blank" rel="noreferrer">
              Entrance picture
            </a>
          </div>
        </>
      )}

      {inst === 'localTrain' && (
        <>
          <p className="t">{t.instructionsTrain}</p>
        </>
      )}
    </>
  )
}
