import capitalize from 'lodash/capitalize'

import Video from './Video.js'
import i18n from './i18n.js'

const CATS = ['food', 'animals', 'bikes', 'main']

export default function Hello({ lang }) {
  const t = i18n[lang]

  return (
    <>
      <div className="mb128 f fdc">
        <p className="t">{t.welcome}</p>
      </div>

      <div>
        {CATS.map((cat, i) => (
          <div className="f fdc" key={cat}>
            <img
              className={`banner ${i === 0 ? 'first' : ''}`}
              src={`/banner-${cat}.png`}
              width={256}
            />

            <div className={`f recap ${i % 2 ? 'even' : 'odd'}`}>
              <Video
                src={`https://mediafiles.balthazar.dev/dl/recap-${cat}-crop.mp4`}
                className="square-vid"
              />
              <span>{t[`recap${capitalize(cat)}Desc`]}</span>
            </div>
          </div>
        ))}
      </div>

      <Video
        src="https://mediafiles.balthazar.dev/dl/rings.mp4"
        className="square-vid"
        parentProps={{ className: 'f fjc' }}
      />
    </>
  )
}
